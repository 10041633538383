// components/TextSection.tsx
import React from 'react';
import { Box } from '@mui/material';
import './TextSection.css';

type TextSectionProps = {
  backgroundImage: string;
  mainText: string;
  secondaryText: string;
};

const TextSection: React.FC<TextSectionProps> = ({ backgroundImage, mainText, secondaryText }) => {
  // Separar "Nova" del resto del texto
  const [firstWord, ...restOfMainText] = mainText.split(' ');

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '60vh', // Ajuste de altura basado en el viewport
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 5%', // Padding horizontal para evitar bordes pegados
      }}
    >
      <Box
        sx={{
          maxWidth: '80%',
          textAlign: 'center', // Alinear el texto al centro
        }}
      >
        <p className="main-text">
          <span className="highlight">{firstWord}</span> {restOfMainText.join(' ')}
        </p>
        <p className="secondary-text">{secondaryText}</p>
      </Box>
    </Box>
  );
};

export default TextSection;
