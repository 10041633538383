import React from 'react';
import { Box } from '@mui/material';
import CarouselSection from './CarouselSection';
import CorporateInfoSection from './CorporateInfoSection';
import ProjectsSection from './ProjectsSection';
import ContactSectionv2 from './ContactSectionv2';
import TextSection from '../new_version/TextSection';
import SplitSectionLeftRight from '../new_version/SplitSectionLeftRight';
import FormSection from '../new_version/FormSection';
import HeaderSection from '../new_version/HeaderSection';

const HomePage: React.FC = () => {
  return (
    <Box sx={{ width: '100%', overflowX: 'hidden', margin: 0, padding: 0 }}>
      {/* Sección de Encabezado */}
      <Box sx={{ width: '100%', margin: 0, padding: 0 }}>
        <HeaderSection
          backgroundImage="/images/new_version/4.png"
          centerImage="/images/new_version/10.png"
          title="Nova"
          subtitle="Bienvenido a nuestra plataforma"
          scrollToSectionId="next-section"
        />

      </Box>

      {/* Sección de Texto */}
      <Box sx={{ width: '100%', margin: 0, padding: 0 }}>
        <TextSection 
          backgroundImage="/images/new_version/4.png"
          mainText="Nova es una startup que busca cambiar la forma y gestión de tu emprendimiento."
          secondaryText="Brindamos servicios de Ingeniería empresarial de calidad haciendo el uso de herramientas y métodos adecuados a las necesidades de su empresa o negocio."
        />
      </Box>

      {/* Sección Dividida Izquierda/Derecha */}
      <Box sx={{ width: '100%', margin: 0, padding: 0 }}>
        <SplitSectionLeftRight 
          backgroundImage="/images/new_version/4.png"
          leftImage="/images/new_version/10.png"
          middleText="Software salón de belleza..."
          leftButtons={
            <>
              <button>Prueba gratis</button>
              <button>Entrar</button>
            </>
          }
          rightImage="/images/new_version/6.png"
        />
      </Box>

      {/* Sección de Formulario */}
      <Box sx={{ width: '100%', margin: 0, padding: 0 }}>
        <FormSection 
          backgroundImage="/images/new_version/4.png"
          topImage="/images/new_version/10.png"
          middleImage="/images/new_version/8.png"
        />
      </Box>
    </Box>
  );
};

export default HomePage;
