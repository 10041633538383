// components/FormSection.tsx
import React from 'react';
import { Box } from '@mui/material';
import './FormSection.css';

type FormSectionProps = {
  backgroundImage: string;
  topImage: string;
  middleImage: string;
};

const FormSection: React.FC<FormSectionProps> = ({ backgroundImage, topImage, middleImage }) => {
  return (
    <Box
      className="form-section"
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        display: 'flex',
        height: '70vh', // Ajustar altura para diseño centrado
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 5%', // Añadir padding para evitar bordes pegados en pantallas anchas
      }}
    >
      {/* Sección Izquierda con las Imágenes */}
      <Box
        className="form-section-left"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '40%',
          maxWidth: '300px',
          marginRight: '8%', // Aumenta el margen derecho para más separación horizontal
        }}
      >
        {/* Imagen superior pequeña */}
        <Box sx={{ width: '60%', marginBottom: '20px' }}>
          <img src={topImage} alt="Top Image" style={{ width: '100%', height: 'auto', borderRadius: '12px' }} />
        </Box>
        
        {/* Imagen inferior más grande */}
        <Box sx={{ width: '100%', maxWidth: '280px' }}>
          <img src={middleImage} alt="Middle Image" style={{ width: '100%', height: 'auto', borderRadius: '12px' }} />
        </Box>
      </Box>

      {/* Sección Derecha con el Formulario */}
      <Box
        className="form-section-right"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '40%',
          maxWidth: '350px', // Limitar el ancho del formulario
          backgroundColor: '#2E1A47',
          borderRadius: '16px',
          padding: '20px',
        }}
      >
        <form style={{ width: '100%' }}>
          <input type="text" placeholder="Nombre" className="input-field" />
          <input type="email" placeholder="Correo Electrónico" className="input-field" />
          <input type="tel" placeholder="Número de Celular" className="input-field" />
          <button type="submit" className="submit-button">Agenda tu cita</button>
        </form>
      </Box>
    </Box>
  );
};

export default FormSection;
