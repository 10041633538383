import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';

const ChatWindow: React.FC = () => {
  const [messages, setMessages] = useState<{ user: boolean; text: string }[]>([]);
  const [inputText, setInputText] = useState('');
  const [isVisible, setIsVisible] = useState(false); // Controla la visibilidad desde aquí

  const sendMessage = async () => {
    if (inputText.trim()) {
      setMessages((prev) => [...prev, { user: true, text: inputText }]);

      try {
        const response = await axios.post('https://server-back.azurewebsites.net/llm/questionUser', {
          input_text: inputText,
        });

        setMessages((prev) => [
          ...prev,
          { user: false, text: response.data.response || 'Lo siento, no pude obtener una respuesta.' },
        ]);
      } catch (error) {
        console.error('Error al conectar con el endpoint:', error);
        setMessages((prev) => [
          ...prev,
          { user: false, text: 'Hubo un problema al conectar con el servidor. Inténtalo nuevamente más tarde.' },
        ]);
      }

      setInputText('');
    }
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  if (!isVisible) {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={toggleVisibility}
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          borderRadius: '50px',
          backgroundColor: '#FFA500',
          color: '#0D1B2A',
          padding: '10px 20px',
          textTransform: 'none',
          boxShadow: 3,
          fontWeight: 'bold',
        }}
      >
        Chatea con nosotros
      </Button>
    );
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 80,
        right: 20,
        width: 300,
        height: 400,
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: 3,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          padding: '10px',
          backgroundColor: '#FFA500',
          color: 'white',
          borderRadius: '8px 8px 0 0',
          textAlign: 'center',
        }}
      >
        <Typography variant="h6">Asistente de Nova</Typography>
        <Button
          onClick={toggleVisibility} // Cierra el chat desde aquí
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
            color: 'white',
            fontSize: '0.8rem',
          }}
        >
          Cerrar
        </Button>
      </Box>
      <Box
        sx={{
          flex: 1,
          padding: '10px',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {messages.map((msg, index) => (
          <Box
            key={index}
            sx={{
              marginBottom: '10px',
              textAlign: msg.user ? 'right' : 'left',
            }}
          >
            <Typography
              sx={{
                display: 'inline-block',
                padding: '8px 12px',
                borderRadius: '8px',
                backgroundColor: msg.user ? '#E3F2FD' : '#FFF3E0',
                color: msg.user ? '#0D47A1' : '#BF360C',
              }}
            >
              {msg.text}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: '10px',
          borderTop: '1px solid #ddd',
        }}
      >
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          placeholder="Escribe tu mensaje..."
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={sendMessage}
          sx={{ marginLeft: '10px' }}
        >
          Enviar
        </Button>
      </Box>
    </Box>
  );
};

export default ChatWindow;
