// components/HeaderSection.tsx
import React from 'react';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'; // Íconos de react-icons
import './HeaderSection.css';

type HeaderSectionProps = {
  backgroundImage: string;
  centerImage: string;
  title: string;
  subtitle: string;
  scrollToSectionId: string; // ID de la sección a la que se desplazará al hacer clic en la imagen central
};

const HeaderSection: React.FC<HeaderSectionProps> = ({
  backgroundImage,
  centerImage,
  title,
  subtitle,
  scrollToSectionId,
}) => {
  const handleScroll = () => {
    const targetSection = document.getElementById(scrollToSectionId);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
      className="header-section"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="content-container">
        <div className="center-image" onClick={handleScroll}>
          <img src={centerImage} alt="Center" />
        </div>
        <div className="social-icons">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <FaFacebook size="3vw" color="#fff" />
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <FaInstagram size="3vw" color="#fff" />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <FaTwitter size="3vw" color="#fff" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;
